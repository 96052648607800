import * as React from 'react';
import Box from '@mui/material/Box';
import { TableBody, Typography, Grid, Button, TextField, Select, Paper, InputAdornment, FormControl, Tooltip, Card, Menu, FormControlLabel,CircularProgress } from '@mui/material';
import { SearchOutlined, Verified,CloseOutlined, GridView, DeleteOutline, ListAlt } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DOMPurify from 'dompurify';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useSnackbar } from 'notistack'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//     },
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
export default function FormPropsTextFields() {
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem("userData"))
    
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [cat, setCat] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');
    const [view, setView] = React.useState(false);
    const [catid, setCatId] = React.useState('all');
    const [stat, setStat] = React.useState([])
    const [status, setStatus] = React.useState('all')

    const [tableLoading,setTableLoading]=React.useState(false)
    // Log the current value of status state
    console.log("Selected Status:", status);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    React.useEffect(() => {
        getCategory()
    }, [])
    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory`, { headers: headerlocal }).then((res) => {
            setCat(res.data.category)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    // React.useEffect(() => {
    //     getStatus()
    // }, [])

    // const getStatus = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Event/GetEventsbystatus?status=online&size=1&page=0&search=`, { headers: headerlocal }).then((res) => {
    //         setStat(res.data.status)
    //         console.log(res.data, "statusssssssssss");
    //     }).catch((error) => {
    //         console.log(error.response, "error");
    //     })
    // }

    const Gridview = () => {
        setView(false)
    }
    const Listview = () => {
        setView(true)
    }
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(newdata);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;


        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == true && after.setService == false) {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == false && after.setService == true) {
            currElIndexNumber = after.index_position + 512;

        } else if (before.setService == false && after.setService == true) {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == true && after.setService == false) {
            currElIndexNumber = after.index_position + 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setNewdata(fromvar);

        var body = {
            index_position: currElIndexNumber
        }

        axios.put(process.env.REACT_APP_BASE_URL + `service/updateServiceposition/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
                console.log(res.data, "drag response");
                // getService();
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }

    const handleClick = (event, data) => {
        console.log(data)
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };
    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        getEvents()
    }, [rowsPerPage, page, search, catid, status])

    const getEvents = async () => {
        setTableLoading(true)
    
        await axios.get(process.env.REACT_APP_BASE_URL + `Event/GetEventsByTeacherId?createdby=${params && params.id}&size=${rowsPerPage}&page=${page}&search=${search}&Category_id=${catid}&status=${status}`, { headers: headerlocal }).then((res) => {
         

            setNewdata(res.data.events)
            setTotal(res.data.totalcount)
        }).catch((error) => {
            console.log(error, "error");
        }).finally(()=>{
            setTimeout(() => {
            setTableLoading(false)
        }, 1500);
        })
    }

    const handleDeleteEvent = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Event/DeleteEvent/${deleteid}`, {}, { headers: headerlocal }).then((res) => {
            enqueueSnackbar("Event deleted successfully", { variant: 'success' })
            getEvents()
            handleClose()
        }).catch((error) => {
            console.log(error);
        })
    }
    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id);
        setOpen(true);
    };
    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };
    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate(`/teachers/details/events/edit/${params.id}`, { state: val })
    }
    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)
    }
    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }

    // const handleChangeStatus = async (e, data, type) => {
    //     // console.log(type, 'typetypetypetype');
    //     if (type === 'mega_event') {
    //         const body = {
    //             mega_event: e.target.checked,
    //         };
    //         console.log(body, "Mega Event");
    //         try {
    //             const response = await axios.put(process.env.REACT_APP_BASE_URL + `Event/updateMegaEvent/${data._id}`, body, { headers: headerlocal });
    //             getEvents();
    //         } catch (error) {
    //             enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
    //             console.error("Error while updating mega event", error);
    //         }
    //     }
    //     if (type === 'Public') {
    //         const body = {
    //             public: e.target.checked,
    //         };
    //         console.log(body, "public");
    //         try {
    //             const response = await axios.put(process.env.REACT_APP_BASE_URL + `Event/updatePublic/${data._id}`, body, { headers: headerlocal });
    //             getEvents();
    //         } catch (error) {
    //             enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
    //             console.error("Error while updating public", error);
    //         }
    //     }
    //     if (type === 'Primary') {
    //         const body = {
    //             primary_event: e.target.checked,
    //         };
    //         console.log(body, "primary");
    //         try {
    //             const response = await axios.put(process.env.REACT_APP_BASE_URL + `Event/updatePrimaryEvent/${data._id}`, body, { headers: headerlocal });
    //             getEvents();
    //         } catch (error) {
    //             enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
    //             console.error("Error while updating primary event", error);
    //         }
    //     }
    // };
    const sanitizedContent = DOMPurify.sanitize(alldata.event_description, { ALLOWED_TAGS: ['img'] });
    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }
    return (
        <>
            {view == false ?
                <Box className='whiteContainer p-20'>
                    <Box className='flex-center flex-wrap mb-20 '>
                        {/* <Box className='flex-center mr-10'>
                            <Typography component="div" className="label mr-10 mb-0">Status: </Typography>
                            <FormControl fullWidth className='minw80' >
                                <Select className="select"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status ? status : 'all'}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={'online'}>Online</MenuItem>
                                    <MenuItem value={'offline'}>Offline</MenuItem>

                                </Select>
                            </FormControl>
                        </Box> */}
                        {/* <Box className="mr-10 ">
                            <Tooltip title="Grid View"><Button className={view == false ? 'active_tab' : 'inactive_tab'} onClick={Gridview}><GridView /></Button></Tooltip>
                            <Tooltip title="List View"> <Button className={view == true ? 'active_tab' : 'inactive_tab'} onClick={Listview}><ListAlt /></Button></Tooltip>
                        </Box> */}
                        <Box component='div' className='DTsearch mr-10'>
                            <FormControl variant="standard">
                                <Typography component='div' className='label mr-10'>Search :</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    value={search}
                                    className='textfield search'
                                    onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                    placeholder='Search Here...'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {search !== '' && (
                                                    <IconButton onClick={handleRemove} size="small">
                                                        <CloseOutlined className='fz-18' />
                                                    </IconButton>
                                                )}
                                                <SearchOutlined className='fz-18' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                        {/* <Box className='flex-center'>
                            <Typography component="div" className="label mr-10 mb-0">Category: </Typography>
                            <FormControl fullWidth className='minw200' >
                                <Select className="select"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={(e) => setCatId(e.target.value)}
                                    value={catid ? catid : 'all'}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    {cat && cat.length > 0 && cat.map((data) => (
                                        <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box> */}
                        {/* {catid != 'all' || search != '' ?
                            <Button onClick={handleRemove} className='theme-btn ml-10'>Reset</Button>
                            : ""} */}
                    </Box>

                    <Box >
                        <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                            {tableLoading?(

<Grid item xs={12} className="text-center">
<CircularProgress sx={{ color: '#F3714D' }} />
</Grid>
                            ):(
                            
                            newdata && newdata.length > 0 ? newdata.map((data, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3} className='d-flex' sx={{ marginTop: 2 }}>
                                        <Card className="cardGrid" >
                                            <IconButton aria-label="more" id="long-button"
                                                aria-controls={menuopen ? 'long-menu' : undefined}
                                                aria-expanded={menuopen ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, data)}
                                                className='vertical_menu'>
                                                <MoreVertIcon />
                                            </IconButton>
                                            {auth.role_id === 2 && auth.planId.Event && (auth.planId.Event.viewEvent !== false || auth.planId.Event.deleteEvent !== false || auth.planId.Event.editEvent !== false) && <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={menuopen}
                                                onClose={handleClose_menu}
                                                PaperProps={{
                                                    style: {
                                                        width: '15ch',
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                {/* {auth.planId.Event.viewEvent !== false && <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>} */}
                                                {auth.planId.Event.editEvent !== false && <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>}
                                                {auth.planId.Event.deleteEvent !== false && <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>}
                                            </Menu>}
                                            {auth.role_id === 1 && <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={menuopen}
                                                onClose={handleClose_menu}
                                                PaperProps={{
                                                    style: {
                                                        width: '15ch',
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
                                                <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                                <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                            </Menu>}
                                            <div className="carosuel">
                                                <Swiper
                                                    modules={[Navigation, Pagination]}
                                                    className="mySwiper "
                                                    slidesPerView={1}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                >{data && data.allimages && data.allimages?.length > 0 && data.allimages.map((tdata, i) => (
                                                    <SwiperSlide key={i}> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                ))}
                                                </Swiper>
                                            </div>
                                            <CardContent>
                                                <Box className='mb-10 d-flex align-items-center justify-content-between' >
                                                    <Typography noWrap className='semibold title fz-18' variant="body" component="div">{data.event_name}</Typography>
                                                    {
                                                        data.verifybadgeEVent && <Typography component='div' variant='body1' className='fz-14' style={{ display: 'flex', alignItems: 'center' }}><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography>
                                                    }
                                                </Box>
                                                {/* <Box className='mb-10 d-flex align-items-start justify-content-between flex-wrap' >
                                                    <Box className='' >
                                                        <Typography component='div' variant='body1' className='fz-14 mr-5 semibold minw80'>Mega Event:</Typography>
                                                        <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={
                                                            <Switch size="small" onChange={(e) => handleChangeStatus(e, data, 'mega_event')} checked={data.mega_event} />
                                                        } />
                                                    </Box>
                                                    <Box className='' >
                                                        <Typography component='div' variant='body1' className='fz-14 mr-5 semibold '>Public:</Typography>
                                                        <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size="small" onChange={(e) => handleChangeStatus(e, data, 'Public')} checked={data.public} />} />
                                                    </Box>
                                                </Box>
                                                <Box className='mb-10 ' >
                                                    <Typography component='div' variant='body1' className='fz-14 mr-5 semibold minw80'>Primary:</Typography>
                                                    <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size="small" onChange={(e) => handleChangeStatus(e, data, 'Primary')} checked={data.primary_event} />} />
                                                </Box> */}
                                             
                                                {data && data.shortDescription ?
                                                    <Typography component='div' variant='body1' className='fz-14 ellipse2 mb-10'>{data && data.shortDescription}</Typography>
                                                    : ""}
                                                <Box className=' ' >
                                                <Box className='d-flex mb-10' >
                                                        <Typography component='div' variant='body1' className='fz-14  semibold mr-5'>Timings:</Typography>
                                                        <Box>
                                                            <Typography component='div' variant='body1' className='fz-14 '>{moment(data.fromTime).format("DD MMM YYYY h:mm a")} <b>to</b></Typography>
                                                            <Typography component='div' variant='body1' className='fz-14 '>{moment(data.ToTime).format("DD MMM YYYY h:mm a")}</Typography>
                                                        </Box>
                                                    </Box>

                                                    {data.status === "offline" ? <Box className='d-flex' >
                                                        <Typography component='div' variant='body1' className='fz-14 semibold mr-5'>Location:</Typography>
                                                        <Typography component='div' variant='body1' className='fz-14 '>{data && data.formatted_address}</Typography></Box> :
                                                        <Box className='d-flex' >
                                                            <Typography component='div' variant='body1' className='fz-14 semibold mr-5'>Location:</Typography>
                                                            <Typography component='div' variant='body1' className='fz-14 '>online</Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p></Grid>)}
                        </Grid>
                        <Dialog open={open} keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className='custom_modal'>
                            <DialogTitle>Would you like to delete Event?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleClose} className='cancel_btn'>No</Button>
                                <Button onClick={handleDeleteEvent} className='theme-btn'>Yes</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[8, 12, 16, 20, 24]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='DT-pagination' />
                </Box>

                :
                <Box className='whiteContainer '>
                    <Box className='flex-center flex-wrap  p-20'>
                        {/* <Box className='flex-center mr-10'>
                            <Typography component="div" className="label mr-10 mb-0">Status: </Typography>
                            <FormControl fullWidth className='minw80' >

                                <Select className="select"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status ? status : 'all'}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={'online'}>Online</MenuItem>
                                    <MenuItem value={'offline'}>Offline</MenuItem>

                                </Select>
                            </FormControl>
                        </Box> */}
                        {/* <Box className="mr-10">
                            <Tooltip title="Grid View"><Button className={view == false ? 'active_tab' : 'inactive_tab'} onClick={Gridview}><GridView /></Button></Tooltip>
                            <Tooltip title="List View"> <Button className={view == true ? 'active_tab' : 'inactive_tab'} onClick={Listview}><ListAlt /></Button></Tooltip>
                        </Box> */}

                        <Box component='div' className='DTsearch mr-10'>
                            <FormControl variant="standard">
                                <Typography component='div' className='label mr-10' >Search :</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield search '
                                    placeholder='Search Here...'
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value); setPage(0); }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box className='flex-center'>
                            <Typography component="div" className="label mr-10 mb-0">Category: </Typography>
                            <FormControl fullWidth className='minw80' >

                                <Select className="select"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={(e) => setCatId(e.target.value)}
                                    value={catid ? catid : 'all'}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    {cat && cat.length > 0 && cat.map((data) => (
                                        <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {catid != 'all' || search != '' ?
                            <Button onClick={handleRemove} className='theme-btn ml-10'>Reset</Button>
                            : ""}
                    </Box>
                    <Box>
                        <TableContainer component={Paper} id='table'>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                                <TableHead className='DT-head'>
                                    <TableRow className=''>
                                        <TableCell align="left" className='tbl_head text-center' style={{ minWidth: 70 }}>Drag row</TableCell>
                                        <TableCell align="left" className='tbl_head'>Event name</TableCell>
                                        <TableCell align="left" className='tbl_head text-center' style={{ minWidth: 70 }}>Mega Event</TableCell>
                                        <TableCell align="left" className='tbl_head text-center' style={{ minWidth: 70 }}>Public</TableCell>
                                        <TableCell align="left" className='tbl_head text-center' style={{ minWidth: 70 }}>Primary</TableCell>
                                        <TableCell align="left" className='tbl_head'>From Time</TableCell>
                                        <TableCell align="left" className='tbl_head'>To Time</TableCell>
                                        <TableCell align="left" className='tbl_head'>Location</TableCell>


                                    </TableRow>
                                </TableHead>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (

                                            <TableBody className="characters " {...provided.droppableProps} ref={provided.innerRef}>
                                                {newdata && newdata?.length > 0 ? newdata.map((row, i) => (

                                                    <Draggable key={row._id} draggableId={row._id} index={i}>
                                                        {(provided) => (
                                                            <>
                                                                <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className=''>
                                                                    <TableCell className='DT-row' align="center"><DragHandleIcon /></TableCell>

                                                                    {row && row.event_name ? <TableCell className='DT-row' align="left" style={{ minWidth: 200 }}>{row && row.event_name}{row && row.verifybadgeEVent == true ? <Typography component='span' variant='body1' className='fz-14 '>    <Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography> : ""}</TableCell> : <TableCell className='DT-row' style={{ minWidth: 200 }}>-</TableCell>}

                                                                    {/* <StyledTableCell align="center" className='DT-row'>
                                                                        <FormControlLabel
                                                                            className='m-0'
                                                                            control={<Switch size="small"
                                                                                onChange={(e) => handleChangeStatus(e, row, 'mega_event')}
                                                                                checked={row.mega_event}
                                                                            />} />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center" className='DT-row'>
                                                                        <FormControlLabel
                                                                            className='m-0'
                                                                            control={<Switch size="small"
                                                                                onChange={(e) => handleChangeStatus(e, row, 'Public')}
                                                                                checked={row.public}
                                                                            />} />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center" className='DT-row'>
                                                                        <FormControlLabel
                                                                            className='m-0'
                                                                            control={<Switch size="small"
                                                                                onChange={(e) => handleChangeStatus(e, row, 'Primary')}
                                                                                checked={row.primary_event}
                                                                            />} />
                                                                    </StyledTableCell> */}

                                                                    <StyledTableCell align="left" className='DT-row'>{moment(row.fromTime, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("DD MMM YYYY h:mm a")}</StyledTableCell>
                                                                    <StyledTableCell align="left" className='DT-row'>{moment(row.ToTime, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("DD MMM YYYY h:mm a")}</StyledTableCell>
                                                                    <StyledTableCell align="left" className='DT-row'>{row.address ? row.address : 'Online'}</StyledTableCell>



                                                                </TableRow>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                )) : <TableRow className=''>
                                                    <TableCell className='DT-row' align="center" colSpan={5}>
                                                        No Data Found
                                                    </TableCell>
                                                </TableRow>}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[8, 12, 16, 20, 24]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className='DT-pagination' />
                    </Box>
                </Box>}

            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'><DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Event Details</DialogTitle>
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="carosuel">
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper "
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}>{alldata && alldata.allimages && alldata.allimages?.length > 0 && alldata.allimages.map((tdata, i) => (
                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>))}
                                </Swiper>
                            </div>
                        </Grid>

                        <Grid style={{ marginTop: '-200px' }} item xs={12} sm={12} md={6} lg={6}>
                            <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Event: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.event_name}</Typography>
                            </Box>
                        </Grid>
                        {alldata.shortDescription && 
                        <Grid style={{ marginTop: '-280px',textAlign:'right',marginLeft:'410px' }} item xs={12} sm={12} md={6} lg={6}>
                        
                            
                            <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Short Description: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.shortDescription}</Typography>
                            </Box>
                         </Grid>
                        }
                    </Grid>
                    {/* <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography> */}
                    <Typography component='span' variant='body1' className='fz-15 '>{alldata.shortDescription}</Typography>

                    {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' ></Typography>}

                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}