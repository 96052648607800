import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MiniDrawer from "../../../../../components/drawer";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Sidebar from "./../../Sidebar";
import { Close, Dvr, ListAlt } from "@mui/icons-material";
import CompareIcon from "@mui/icons-material/Compare";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Card,
  Box,
  CardContent,
  Dialog,
  Checkbox,
  ListItemText,
  Paper,
  Input,
  DialogContent,
  InputBase,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
  TextareaAutosize,
  CardActions,
  Menu,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
} from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "swiper/css/navigation";

import { usePlacesWidget } from "react-google-autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import EmailEditor from "react-email-editor";
import AutoComplete_Edit from "./Autocomplete_edit";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18.5,
    height: 18.5,
  },
  "& .MuiSwitch-track": {
    borderRadius: 18.5 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#db0a07" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: "#f3714d",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: "#f3714d",
		},
	},
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#f3714d",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#f3714d",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <ListAlt />,
    2: <CompareIcon />,
    3: <Dvr />,
  };
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#f3714d",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#f3714d",
  },
});

const steps = ["Basic Information", "Event Information", "Event Description"];

export default function Service_Steppyform() {
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("userData"));
  const headerlocal = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token"),
  };
  const headerformdata = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("token"),
  };
  let location = useLocation();
  const locationLat = location.state.data.location_coord.coordinates;

  console.log(locationLat, "locationLat");
  const params = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [event, setEvent] = React.useState({
    eventname: location.state.data.event_name,
    description: location.state.data.shortDescription,
    organizer: location.state.data.event_Organizer,
    link: location.state.data.link,
  });
  const [truebutton, setTruebutton] = React.useState(false);
  // const [error, setError] = React.useState({ eventname: "" })
  const [error, setError] = React.useState({
    eventname: "",
    status: "",
    organizer: "",
    fromTime: "",
    toTime: "",
    video: "",
    location: "",
  });
  const [categoryId, setCategoryId] = React.useState(
    location.state.data.category_id
  );
  const [subcategoryId, setSubCategoryId] = React.useState(
    location.state.data.Subcategory_id ? location.state.data.Subcategory_id : ""
  );

  console.log(location.state.data,"eventsssss");
  
  const [catError, setCatError] = React.useState("");
  const [subcatError, setSubCatError] = React.useState("");
  const [imageError, setImageError] = React.useState("");
  const [state1, setState1] = React.useState(
    location.state.data.additionalimages
  );
  const [state2, setState2] = React.useState(
    location.state.data.additionalimages
  );
  const [existingimages, setExistingimages] = React.useState(
    location.state.data.additionalimages
  );
  const [statesingle, setStatesingle] = React.useState([
    ...[],
    location.state.data.image,
  ]);
  const [statesingle2, setStatesingle2] = React.useState([
    ...[],
    location.state.data.image,
  ]);
  const [richeditor, setRichtor] = React.useState(
    location.state.data.event_description
  );
  const [description, setDescription] = React.useState(
    location.state.data.shortDescription
  );
  const [imageAddError, setImageAddError] = React.useState("");
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const dataofeditor = `${richeditor}`;
  const [convertedContent, setConvertedContent] = React.useState(null);
  const [selectedFromDateTime, setSelectedFromDateTime] = useState(
    dayjs(location.state.data.fromTime)
  );
  const [selectedToDateTime, setSelectedToDateTime] = useState(
    dayjs(location.state.data.ToTime)
  );
  const [selectedVideo, setSelectedVideo] = useState([
    ...[],
    location.state.data.Video,
  ]);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState([
    ...[],
    location.state.data.Video,
  ]);
  const [cityName, setCityName] = useState(
    // location.state.data.location_coord.coordinates
    location.state.data.formatted_address
  );
  const [lat, setLat] = useState(locationLat[0]);
  const [lng, setLng] = useState(locationLat[1]);
  const [formattedadrress, setFormattedAddress] = useState(
    location.state.data.formatted_address
  );
  const [subcat, setSubcat] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const emailEditorRef = useRef({ editor: {} });
  const [descJson, setDescJson] = React.useState(
    location.state.data.DescriptionJSON
  );
  const [status, setStatus] = React.useState(location.state.data.status);
  // const [isToggled, setToggled] = React.useState(
  //   location.state.data.mega_event
  // );
  // const [isPublic, setPublic] = React.useState(location.state.data.public);
  // const [isPrimary, setPrimary] = React.useState(
  //   location.state.data.primary_event
  // );
  const [selectGallert, setSelectGallery] = useState(
    location.state.data.gallery
  );
  const [tag, setTag] = React.useState("");
  const [newTag, setNewTag] = useState("");
  const [tagError, setTagError] = useState("");
  const [ads, setAds] = React.useState([]);
  const [selectOwner, setSelectOwner] = useState(location.state.data.ads);
  const [checkboxValue, setCheckboxValue] = useState(
    location.state.data.verifybadgeEVent
  );

  // const handleToggle = () => {
  //   setToggled(!isToggled);
  // };
  // const handleTogglePublic = () => {
  //   setPublic(!isPublic);
  // };
  // const handleTogglePrimary = () => {
  //   setPrimary(!isPrimary);
  // };
  const handleEditGallery = (e) => {
    setSelectGallery(selectGallert === "Image" ? "Video" : "Image");
  };

  const handleEditOwner = (e) => {
    setSelectOwner(e.target.value);
  };

  //add management
  React.useEffect(() => {
    getAds();
  }, []);

  const getAds = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `AddManagement/getAddManagement`,
        { headers: headerlocal }
      );
      setAds(response.data.getAddManagement);
      setAdsDataUpdated(response.data.getAddManagement);
      console.log(response, "teachers");
    } catch (error) {
      console.log(error.response, "error");
    }
  };

  const [adsUpdated, setAdsUpdated] = React.useState([]);
  const [adsDataUpdated, setAdsDataUpdated] = React.useState([]);

  const [selectedAds, setSelectedAds] = React.useState(() => {
    const arrVal = [];
    const adsArr = location.state.data;
    console.log(location.state.data, "location.state.data.ads");
    if (adsArr.length > 0) {
      adsArr.map((item) => {
        arrVal.push(item.title);
      });
    }
    return arrVal;
  });

  React.useEffect(() => {
    getAdsSelected();
  }, [selectedAds, adsDataUpdated]);

  function getAdsSelected() {
    var array = [];
    const selectedAdss = adsDataUpdated.filter((content) =>
      selectedAds.includes(content.title)
    );
    selectedAdss.map((item, i) => {
      var val = { title: item.title };
      Object.assign(item, val);
      array.push(val);
    });
    setAdsUpdated(array);
  }

  const handlechangeAdsSelected = (event, item) => {
    // setError({ ...error, ["userselect"]: '' })
    const {
      target: { value },
    } = event;
    setSelectedAds(typeof value === "string" ? value.split(",") : value);
    getAdsSelected();
  };

  // const handleCheckboxChange = (event) => {
  //   setCheckboxValue(event.target.checked);
  // };
  // const handleEventVerify = (event) => {
	// 	setCheckboxValue(event.target.value === "verify");
	// };
  console.log(location.state.data, "location.state.data");

  // const handleMegaEvent = async (e) => {
  //   console.log("hitting");
  //   const body = {
  //     mega_event: e.target.checked,
  //   };
  //   try {
  //     const response = await axios
  //       .put(
  //         process.env.REACT_APP_BASE_URL +
  //           `Event/updateMegaEvent/${location.state.data._id}`,
  //         body,
  //         { headers: headerlocal }
  //       )
  //       .then((res) => setToggled(res.data.updateEvent.mega_event));

  //     // console.log(response,'responseresponse');
  //   } catch (error) {
  //     enqueueSnackbar(error.response.data && error.response.data.error, {
  //       variant: "error",
  //     });
  //     console.error("Error while updating mega event", error);
  //   }
  //   console.log(body, "mega_event");
  // };

  // const handleMegaEvent = async (e, data) => {
  //     const body = {
  //         mega_event: e.target.checked,
  //     };
  //     try {
  //         const response = await axios.put(process.env.REACT_APP_BASE_URL + `Event/updateMegaEvent/${location.state.data._id}`, body, { headers: headerlocal });
  //         // getEvents();
  //     } catch (error) {
  //         enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
  //         console.error("Error while updating mega event", error);
  //     }
  //     console.log(body, "mega_event");
  // }

  const { ref, autocompleteRef } = usePlacesWidget({
    // need to change this API key
    apiKey: "AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8",
    onPlaceSelected: (place) => {
      setCityName(place.location_coord);
      setFormattedAddress(place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      setError((prevError) => ({ ...prevError, location: "" }));
    },
    options: {
      types: "(regions)" | "establishment",
    },
  });

  const unlayerSampleJSON = {
    body: JSON.parse(descJson),
  };
  const emailJSON = unlayerSampleJSON;

  const onLoad = () => {
    emailEditorRef.current.editor.loadDesign(emailJSON);
  };

  React.useEffect(() => {
    if (dataofeditor) {
      try {
        const contentState = stateFromHTML(dataofeditor);
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error during HTML to ContentState conversion:", error);
      }
    }
  }, [dataofeditor]);

  React.useEffect(() => {
    convertContentToHTML();
  }, [editorState]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const convertContentToHTML = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentAsHTML = stateToHTML(currentContent);
    setConvertedContent(currentContentAsHTML);
  };
  const handleRemoveRestart = () => {
    setSubCategoryId("");
  };
  console.log(state2, "state2state2state2state2");

  const handleUpdateService = async () => {
    var multipleImage = state2.filter((x) => x != null);
    var valid = validate();
    var valid11 = validatenew();
    if (valid == true && valid11 == true) {
      setTruebutton(true);
      const unlayer = emailEditorRef.current?.editor;
      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        const sanitizedHtml = DOMPurify.sanitize(html);

        const formData = new FormData();
        formData.append("createdby[]", params.id);
        formData.append("category_id", categoryId);
        formData.append("status", status);
        formData.append("Subcategory_id", subcategoryId);
        formData.append("event_name", event.eventname);
        formData.append("fromTime", selectedFromDateTime);
        formData.append("ToTime", selectedToDateTime);
        formData.append("location_coord", cityName);
        formData.append("latitude", lat);
        formData.append("longitude", lng);
        formData.append("formatted_address", formattedadrress);
        formData.append("event_description", sanitizedHtml);
        formData.append("shortDescription", description);
        formData.append("event_Organizer", event.organizer);
				formData.append("yogaevent", yogaEvent);
        formData.append("link", event.link);
        formData.append(
          "DescriptionJSON",
          design && JSON.stringify(design.body)
        );
        // formData.append("mega_event", isToggled)
        // formData.append("public", isPublic);
        // formData.append("primary_event", isPrimary);
        // formData.append("verifybadgeEVent", checkboxValue);
        formData.append(
          "tags",
          JSON.stringify(tagUpdated.length > 0 ? tagUpdated : [])
        );
        formData.append(
          "gallery",
          selectGallert === "Image" ? "Image" : "Video"
        );
        formData.append(
          "ads",
          JSON.stringify(adsUpdated.length > 0 ? adsUpdated : [])
        );
        formData.append("adsby", selectOwner === "Yes" ? "Yes" : "No");
        
          statesingle2
            ? statesingle2.forEach((item) => {
                formData.append("newimage", item);
              })
            : formData.append("newimage", "");
        

        
          selectGallert === "Image" ? (
            <>
              {multipleImage
                ? multipleImage.forEach((item) => {
                    formData.append("additionalimages", item);
                  })
                : formData.append("additionalimages", "")}
              {selectedVideo
                ? selectedVideo.forEach((item) => {
                    formData.append("Video", "");
                  })
                : formData.append("Video", "")}
              {existingimages
                ? existingimages.forEach((item) => {
                    formData.append("existingimages", item);
                  })
                : formData.append("existingimages", "")}
            </>
          ) : (
            <>
              {multipleImage
                ? multipleImage.forEach((item) => {
                    formData.append("additionalimages", "");
                  })
                : formData.append("additionalimages", "")}
              {selectedVideo
                ? selectedVideo.forEach((item) => {
                    formData.append("Video", item);
                  })
                : formData.append("Video", "")}
              {existingimages
                ? existingimages.forEach((item) => {
                    formData.append("existingimages", "");
                  })
                : formData.append("existingimages", "")}
            </>
          );
        

        console.log([...formData], "formdata in edit");
        axios
          .put(
            process.env.REACT_APP_BASE_URL +
              `Event/updateEvent/${location.state.data._id}`,
            formData,
            { headers: headerformdata }
          )
          .then((res) => {
            setTruebutton(false);
            navigate(`/teachers/details/events/${params && params.id}`);
            setEvent({
              eventname: "",
              description: "",
              organizer: "",
              link: "",
            });
            setCategoryId("");
            setSubCategoryId("");
            setState1([]);
            setState2([]);
            setSelectedVideo([]);
            setVideoPreviewUrl([]);
            enqueueSnackbar("Event updated successfully", {
              variant: "success",
            });
          })
          .catch((err) => {
            setTruebutton(false);
            enqueueSnackbar(
              err.response && err.response.data.error
                ? err.response.data.error
                : "Something went wrong.",
              { variant: "error" }
            );
            console.log(err.response, "error");
          });
      });
    }
  };

  React.useEffect(() => {
    getTags();
  }, []);

  const getTags = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `Tags/GetTags`,
        { headers: headerlocal }
      );
      setTag(response.data.Tag);
      setTagDataUpdated(response.data.Tag);
      console.log(response, "tags");
    } catch (error) {
      console.log(error.response, "error");
    }
  };

  const [tagUpdated, setTagUpdated] = React.useState([]);
  const [tagDataUpdated, setTagDataUpdated] = React.useState([]);
    const [yogaEvent, setYogaEvent] = React.useState(location.state.data.yogaevent);
  const [selectedTag, setSelectedTag] = React.useState(() => {
    const arrVal = [];
    const tagArr = location.state.data.tags;
  
    if (tagArr.length > 0) {
      tagArr.map((item) => {
        arrVal.push(item.tagname);
      });
    }
    return arrVal;
  });

  React.useEffect(() => {
    getTagSelected();
  }, [selectedTag, tagDataUpdated]);

  function getTagSelected() {
    var array = [];
    const selectedTags = tagDataUpdated.filter((content) =>
      selectedTag.includes(content.tagname)
    );
    selectedTags.map((item, i) => {
      var val = { tagname: item.tagname, tag_id: item._id };
      Object.assign(item, val);
      array.push(val);
    });
    setTagUpdated(array);
  }

  const handlechangeTagSelected = (event, item) => {
    // setError({ ...error, ["userselect"]: '' })
    const {
      target: { value },
    } = event;
    setSelectedTag(typeof value === "string" ? value.split(",") : value);
    getTagSelected();
  };

  const validate = () => {
    if (
      !event.eventname &&
      !status &&
      statesingle2?.length == 0 &&
      !event.organizer
    ) {
      setError({
        eventname: "This field is required.",
        status: "This field is required.",
        location: "This field is required.",
        organizer: "This field is required.",
      });
      setImageError("This field is required.");
      return false;
    }
    if (!event.eventname) {
      setError({ eventname: "This field is required." });
      return false;
    }
    if (!status) {
      setError({ status: "This field is required." });
      return false;
    }

    if (status === "offline") {
      if (!cityName) {
        setError({ location: "This field is required." });
        return false;
      }
    }

    if (!categoryId) {
      setCatError("This field is required.");
    }
    if (!event.organizer) {
      setError({ organizer: "This field is required." });
      return false;
    }
    if (selectedFromDateTime && selectedToDateTime) {
      if (selectedFromDateTime.isSame(selectedToDateTime)) {
        setError((prevError) => ({
          ...prevError,
          toTime: "End date cannot be the same as start date.",
        }));
        return false; // Exit function early if validation fails
      } else if (selectedToDateTime.isBefore(selectedFromDateTime)) {
        setError((prevError) => ({
          ...prevError,
          toTime: "End date must be later than the start date.",
        }));
        return false; // Exit function early if validation fails
      }
    }
    // if (!cityName || cityName === 'undefined') {
    //     setError({ location: "This field is required." })
    //     return false
    // }
    if (statesingle2?.length == 0) {
      setImageError("This field is required.");
      return false;
    }
    return true;
  };

  const validatenew = () => {
    // alert(state2.length )
    console.log(state2.length, "lenghttt");
    if (state2.length == 0 && selectedVideo.length == 0) {
      setImageAddError("This field is required.");
      return false;
    }
    return true;
  };

  let name, value;
  const handlechange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setEvent({ ...event, [name]: value });
  };

  React.useEffect(() => {
    getCategory();
  }, []);

  React.useEffect(() => {
    getSubCategory();
  }, [categoryId]);

  const getCategory = async () => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, {
        headers: headerlocal,
      })
      .then((res) => {
        setCategory(res.data.category);
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  };

  const getSubCategory = async () => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`,
        { headers: headerlocal }
      )
      .then((res) => {
        setSubcat(res.data.subcategory);
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  };

  const multipleImgChangeMultiple = (e, index) => {
    setImageAddError("");
    var fileObj = [];
    var fileArray = [];
    var filepath = [];
    fileObj.push(e.target.files);
    for (let i = 0; i <= fileObj[0].length; i++) {
      if (i < fileObj[0].length) {
        fileArray.push(URL.createObjectURL(fileObj[0][i]));
        filepath.push(fileObj[0][i]);
      }
    }
    var data = state1.filter((x) => x != null);
    var data1 = state2.filter((x) => x != null);
    setState1(data);
    setState2(data1);
    setState1((prevValue) => prevValue.concat(fileArray));
    setState2((prevValue) => prevValue.concat(filepath));
  };

  const indexDelete = (e, index) => {
    var filteredpreview = state1.filter((value, i) => {
      return i !== index;
    });
    var filteredraw = state2.filter((val, i) => {
      return i !== index;
    });
    setState1(filteredpreview);
    setState2(filteredraw);
  };


  const handleYogaEvent = () => {
		setYogaEvent(!yogaEvent);
	}; 


  const indexDelete1 = (e, index) => {
    var filteredpreview = statesingle.filter((value, i) => {
      return i !== index;
    });
    var filteredraw = statesingle2.filter((val, i) => {
      return i !== index;
    });
    setStatesingle(filteredpreview);
    setStatesingle2(filteredraw);
  };

  const handleNext = () => {
    const valid = validate();

    if (valid === true) {
      if (activeStep === 1) {
        const validatation = validatenew();

        if (validatation === true) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const CategoryData = (e) => {
    setSubCategoryId("");
    setCategoryId(e.target.value);
    setCatError("");
    setError({ eventname: "" });
    setCatError("");
    setSubCatError("");
  };
  const handleFromDateTimeChange = (newValue) => {
    setSelectedFromDateTime(newValue);
    setError((prevError) => ({ ...prevError, fromTime: "" }));
  };
  const handleToDateTimeChange = (newValue) => {
    setSelectedToDateTime(newValue);
    setError((prevError) => ({ ...prevError, toTime: "" }));
  };
  const clearVideo = (e, index) => {
    setSelectedVideo([]);
    setVideoPreviewUrl([]);
  };

  const SingleImage = (e, index) => {
    setImageError("");
    var fileObj = [];
    var fileArray = [];
    var filepath = [];
    fileObj.push(e.target.files);
    for (let i = 0; i <= fileObj[0].length; i++) {
      if (i < fileObj[0].length) {
        fileArray.push(URL.createObjectURL(fileObj[0][i]));
        filepath.push(fileObj[0][i]);
      }
    }
    var data = statesingle.filter((x) => x != null);
    var data1 = statesingle2.filter((x) => x != null);
    setStatesingle(data);
    setStatesingle2(data1);
    setStatesingle((prevValue) => prevValue.concat(fileArray));
    setStatesingle2((prevValue) => prevValue.concat(filepath));
  };
  const handleVideoChange = (e, index) => {
    setError((prevError) => ({ ...prevError, video: "" }));
    var fileObj = [];
    var fileArray = [];
    var filepath = [];
    fileObj.push(e.target.files);
    for (let i = 0; i <= fileObj[0].length; i++) {
      if (i < fileObj[0].length) {
        fileArray.push(URL.createObjectURL(fileObj[0][i]));
        filepath.push(fileObj[0][i]);
      }
    }
    setVideoPreviewUrl(fileArray);
    setSelectedVideo(filepath);
  };

  const handleNewTagChange = (e) => {
    setNewTag(e.target.value);
  };

  const validationtag = () => {
    if (!newTag) {
      setTagError("Fill the field to add new tag");
      return false;
    }
    return true;
  };

  const handleAddNewTag = () => {
    var validtag = validationtag();
    if (validtag == true) {
      let body = {
        tagname: newTag,
        createdby: auth && auth._id,
      };
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "tags/addTags",
          body,
          headerformdata
        )
        .then((res) => {
          enqueueSnackbar("Tag added successfully", { variant: "success" });
          console.log(res, "tags added");
          const newTagData = { _id: res.data._id, tagname: newTag };
          setTag([...tag, newTagData]);
          setTagDataUpdated([...tagDataUpdated, newTagData]);
          setSelectedTag([...selectedTag, newTag]);
          setNewTag("");
        })
        .catch((err) => {
          enqueueSnackbar(
            err && err.response && err.response.data.message
              ? err.response.data.message
              : "Something went wrong",
            { variant: "error" }
          );
          console.log(err);
        });
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    {
      status == "online" && setFormattedAddress("");
      setLat(0.0);
      setLng(0.0);
      setCityName("");
    }
  };

  const handleEditor = (e) => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      const sanitizedHtml = DOMPurify.sanitize(html);
      setDescJson(JSON.stringify(design.body));
      enqueueSnackbar("Saved changes", { variant: "info" });
    });
  };

  return (
    // <Box className='withSubmenu'>
    <Box className={auth.role_id == 1 ? "withSubmenu" : ""}>
      {auth.role_id == 1 ? (
        <MiniDrawer menuOrder={2} submenuOrder={1} />
      ) : (
        <MiniDrawer menuOrder={3} />
      )}
      {auth.role_id == 1 ? <Sidebar subtype={0} /> : ""}
      <Box component="section" className="contentWraper">
        <Box className="whiteContainer p-20">
          <Typography component="h6" className="formTitle mb-20">
            Edit Event
          </Typography>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Box className="steppy_content">
              <Box className="maxw_700">
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Event Name{" "}
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      id="filled-size-small"
                      variant="outlined"
                      size="small"
                      name="eventname"
                      className="textfield"
                      style={{ width: "100%" }}
                      value={event.eventname}
                      onChange={(e) => {
                        handlechange(e);
                        setError((prevError) => ({
                          ...prevError,
                          eventname: "",
                        }));
                      }}
                    />
                    {error.eventname && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.eventname}
                      </p>
                    )}
                  </FormControl>
                </Box>
                <Box className="mb-20">
{/*                   
                  <FormControlLabel
										className="checkbox_list"
										control={
											// <Checkbox
											// 	checked={checkboxValue}
											// 	onChange={handleEventVerify}
											// 	name='checkbox-value'
											// 	color='primary'
											// />
											<RadioGroup
												className="checkbox_list "
												value={checkboxValue ? "verify" : "guarantee"}
												onChange={handleEventVerify}
											>
												<Grid container spacing={1} alignItems="center">
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															value="verify"
															control={<BpRadio color="primary" />}
															label={
																<Typography
																	component="div"
																	className="label mb-0"
																>
																	Verified
																</Typography>
															}
														/>
														<FormControlLabel
															value="guarantee"
															control={<BpRadio color="primary" />}
															label={
																<Typography
																	component="div"
																	className="label mb-0"
																>
																	Guaranteed
																</Typography>
															}
														/>
													</Grid>
													
												</Grid>
											</RadioGroup>
										}
										label={
											<Typography component="div" className="label mb-0">
												Do you want this event verified or guaranteed?{" "}
											</Typography>
										}
									/> */}
                  {/* <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isToggled}
                        onChange={(e) => handleMegaEvent(e)}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Do you want to add this event as Mega Event?{" "}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isPublic}
                        onChange={handleTogglePublic}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Do you want to make this event as Public?{" "}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isPrimary}
                        onChange={handleTogglePrimary}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Do you want to make this event as Primary?{" "}
                      </Typography>
                    }
                  /> */}
                  <FormControlLabel
                                      className="checkbox_list"
                                      control={
                                        <Checkbox
                                          checked={yogaEvent}
                                          onChange={handleYogaEvent}
                                          name="checkbox-value"
                                          color="primary"
                                        />
                                      }
                                      label={
                                        <Typography component="div" className="label mb-0">
                                          Is this Event a yogaevent.com event?{" "}
                                        </Typography>
                                      }
                                    />
                </Box>

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Mode
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status ? status : "sel"}
                      placeholder="Select mode of event"
                      className="select"
                      onChange={handleStatus}
                    >
                      <MenuItem disabled value={"sel"}>
                        Select status of course
                      </MenuItem>
                      <MenuItem value={"online"}>Online</MenuItem>
                      <MenuItem value={"offline"}>Offline</MenuItem>
                    </Select>
                    {error.status && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.status}
                      </p>
                    )}
                  </FormControl>
                </Box>

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Tags <Typography component="span"></Typography>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      className="select"
                      value={selectedTag}
                      onChange={(e) => handlechangeTagSelected(e)}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const selectedTagss = tagDataUpdated.filter((tag) =>
                          selected.includes(tag.tagname)
                        );
                        if (selectedTagss.length === 0) {
                          return "Any tag";
                        } else if (selectedTagss.length === 1) {
                          return selectedTagss[0].tagname;
                        } else {
                          return `${selectedTagss.length} Tags slected`;
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      {tag && tag.length > 0 ? (
                        tag.map((name) => (
                          <MenuItem key={name._id} value={name.tagname}>
                            <Checkbox
                              checked={selectedTag.indexOf(name.tagname) > -1}
                            />
                            <ListItemText primary={name.tagname} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No tag found</MenuItem>
                      )}
                    </Select>
                    {/* <p className='error-field'>{error.userselect}</p> */}
                  </FormControl>
                </Box>

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    New Tag
                  </Typography>
                  <Box className="flex-center align-items-start">
                    <FormControl
                      variant="standard"
                      fullWidth
                      className="flex-1"
                    >
                      <TextField
                        id="filled-size-small"
                        variant="outlined"
                        placeholder="New Tag"
                        size="small"
                        name="newTag"
                        className="textfield mr-10 "
                        value={newTag}
                        onChange={handleNewTagChange}
                      />
                      {tagError && (
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {tagError}
                        </p>
                      )}
                    </FormControl>
                    <Button
                      onClick={handleAddNewTag}
                      variant="contained"
                      className="theme-btn mt-10"
                    >
                      Add Tag
                    </Button>
                  </Box>
                </Box>

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Event Organizer{" "}
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      id="filled-size-small"
                      variant="outlined"
                      placeholder="Event Organizer"
                      size="small"
                      name="organizer"
                      className="textfield"
                      style={{ width: "100%" }}
                      value={event.organizer}
                      onChange={(e) => {
                        handlechange(e);
                        setError((prevError) => ({
                          ...prevError,
                          organizer: "",
                        }));
                      }}
                    />
                    {error.organizer && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.organizer}
                      </p>
                    )}
                  </FormControl>
                </Box>

                {/* <Box className="mb-20 ">
                                <Typography component='div' className='label'>Would you like to display this ads?</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-customized-radios"
                                    name="payment_type"
                                    onChange={handleEditOwner}
                                    value={selectOwner}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        className='radio-btns'
                                        control={<BpRadio />}
                                        label={<Typography className='fz-14'>Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        value="No"
                                        className='radio-btns'
                                        control={<BpRadio />}
                                        label={<Typography className='fz-14'>No</Typography>}
                                    />
                                </RadioGroup>
                            </Box> */}

                {/* {selectOwner === 'Yes' && (
									 <Box className="mb-20">
                                     <Typography component='div' className='label'>Select Ads <Typography component='span'></Typography></Typography>
                                     <FormControl fullWidth>
                                         <Select
                                             labelId="demo-multiple-checkbox-label"
                                             id="demo-multiple-checkbox"
                                             multiple
                                             className='select'
                                             value={selectedAds}
                                             onChange={(e) => handlechangeAdsSelected(e,)}
                                             input={<OutlinedInput />}
                                             renderValue={(selected) => {
                                                 const selectedAdsss = adsDataUpdated.filter(ads => selected.includes(ads.title));
                                                 if (selectedAdsss.length === 0) {
                                                     return "Any ads";
                                                 } else if (selectedAdsss.length === 1) {
                                                     return selectedAdsss[0].title;
                                                 } else {
                                                     return `${selectedAdsss.length} ads selected`;
                                                 }
                                             }}
                                             MenuProps={MenuProps}
                                         >
                                             {ads && ads.length > 0 ? ads.map((name) => (
                                                 <MenuItem key={name._id} value={name.title} >
                                                     <Checkbox checked={selectedAds.indexOf(name.title) > -1} />
                                                     <ListItemText primary={name.title} />
                                                 </MenuItem>
                                             )) : <MenuItem>No ads found</MenuItem>}
                                         </Select>
                                         {/* <p className='error-field'>{error.userselect}</p> */}
                {/* </FormControl> */}
                {/* </Box> */}
                {/* )} */}

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Category{" "}
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      className="select"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={categoryId ? categoryId : "sel"}
                      onChange={(e) => {
                        CategoryData(e);
                      }}
                    >
                      <MenuItem disabled value={"sel"}>
                        Select category
                      </MenuItem>
                      {category && category?.length > 0 ? (
                        category.map((data) => (
                          <MenuItem value={data._id}>
                            {data.category_title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>{"No Record's found"}</MenuItem>
                      )}
                    </Select>
                    {catError && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {catError}
                      </p>
                    )}
                  </FormControl>
                </Box>
                <Box className="mb-20">
                  <Typography
                    component="div"
                    className="label"
                    style={{ marginRight: "10px" }}
                  >
                    Select Sub Category
                  </Typography>
                  <Box
                    className="mb-10"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControl fullWidth style={{ flex: 1 }}>
                      <Select
                        className="select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={categoryId !== "" ? false : true}
                        value={subcategoryId ? subcategoryId : "sel18"}
                        onChange={(e) => {
                          setSubCategoryId(e.target.value);
                          setSubCatError("");
                        }}
                      >
                        <MenuItem disabled value={"sel18"}>
                          Select sub category
                        </MenuItem>
                        {subcat && subcat?.length > 0 ? (
                          subcat.map((data5) => (
                            <MenuItem value={data5._id}>
                              {data5.subcategory_title}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>{"No Record's found"}</MenuItem>
                        )}
                      </Select>
                      {subcatError && (
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {subcatError}
                        </p>
                      )}
                    </FormControl>
                    {subcategoryId !== "" && (
                      <RestartAltIcon
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => handleRemoveRestart()}
                      />
                    )}
                  </Box>
                </Box>

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Link{" "}
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      id="filled-size-small"
                      variant="outlined"
                      placeholder="Link"
                      size="small"
                      name="link"
                      className="textfield"
                      style={{ width: "100%" }}
                      value={event.link}
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </FormControl>
                </Box>

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Start Date
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl
                    variant="standard"
                    fullWidth
                    className="datepicker"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                      >
                        <DateTimePicker
                          onChange={handleFromDateTimeChange}
                          value={selectedFromDateTime && selectedFromDateTime}
                          minDate={dayjs()}
                          renderInput={(props) => <TextField {...props} />}
                          format="DD-MM-YYYY hh:mm a"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          className="textfield"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {error.fromTime && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.fromTime}
                      </p>
                    )}
                  </FormControl>
                </Box>
                <Box className="mb-10">
                  <Typography component="div" className="label">
                    End Date
                    <Typography component="span" className="star"></Typography>
                  </Typography>
                  <FormControl
                    variant="standard"
                    fullWidth
                    className="datepicker"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                      >
                        <DateTimePicker
                          size="small"
                          onChange={handleToDateTimeChange}
                          value={selectedToDateTime && selectedToDateTime}
                          minDate={dayjs(selectedFromDateTime)}
                          renderInput={(props) => <TextField {...props} />}
                          format="DD-MM-YYYY hh:mm a"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          className="textfield"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {error.toTime && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.toTime}
                      </p>
                    )}
                  </FormControl>
                </Box>
                {/* 
                               { status == 'offline'?<Box className="mb-10">
                                    <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                    <input
                                        defaultValue={formattedadrress}
                                        style={{ outline: "none", fontFamily: "Nunito,sans-serif", fontSize: "14px", width: '100%' }}
                                        ref={ref}
                                        placeholder="location"
                                    />
                                </Box> : " "} */}

                {status === "offline" && (
                  <Box className="mb-10">
                    <Typography component="div" className="label">
                      Location <Typography component="span" className="star">
                      *
                    </Typography>
                    </Typography>
                    <AutoComplete_Edit
                      cityName={cityName}
                      setError={setError}
                      setCityName={setCityName}
                      lat={lat}
                      setLat={setLat}
                      lng={lng}
                      setLng={setLng}
                      formattedadrress={formattedadrress}
                      setFormattedAddress={setFormattedAddress}
                    />
                    {error.location && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.location}
                      </p>
                    )}
                  </Box>
                )}

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Primary Image{" "}
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                    {statesingle.length < 1 && (
                      <Button
                        className="multiple_upload_btn"
                        variant="contained"
                        component="label"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                          Upload
                        </Box>
                        <input
                          accept={"image/png, image/jpg,image/jpeg"}
                          onChange={SingleImage}
                          hidden
                          type="file"
                        />
                      </Button>
                    )}
                  </Box>
                  {imageError && (
                    <p style={{ color: "red" }} className="fz-13 m-0">
                      {imageError}
                    </p>
                  )}
                </Box>
                <Box className="mb-20">
                  {statesingle.length > 0 && (
                    <Grid
                      container
                      columnSpacing={1}
                      rowSpacing={1}
                      className="multiple_upload_container mb-10"
                    >
                      {statesingle.length > 0
                        ? statesingle.map((x, i) => (
                            <Grid item xs={6} sm={4} md={4} lg={4}>
                              <Box className="uploaded_img">
                                <Button
                                  className="close_icon"
                                  onClick={(e) => indexDelete1(e, i)}
                                >
                                  <Close />
                                </Button>
                                <img
                                  src={
                                    x.slice(0, 4) === "blob"
                                      ? x
                                      : process.env.REACT_APP_BASE_URL + `${x}`
                                  }
                                  alt=""
                                  height={155}
                                  width={195}
                                />
                              </Box>
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  )}
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    className=" fz-12 mt-10 mb-20"
                    component="p"
                    variant="p"
                  >
                    <strong>Note:</strong> Image size should be more than 280px
                    * 280px for better quality picture.
                  </Typography>
                </Grid>

                <Box className="mb-20">
                  <Box className="mb-10">
                    <Typography component="div" className="label">
                      Short Description
                    </Typography>
                    <FormControl variant="standard" fullWidth>
                      <textarea
                        placeholder="Short Description"
                        className="textarea"
                        defaultValue={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={4}
                        cols={40}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  className="heading-btns mt-40"
                  style={{ textAlign: "end" }}
                >
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    className="theme-btn  "
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {activeStep === 1 && (
            <Box className="steppy_content mb-10">
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                className=" mb-10"
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography component="div" className="label">
                    Images/Video{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <Box className=" ">
                    <RadioGroup
                      row
                      aria-labelledby="demo-customized-radios"
                      name="payment_type"
                      onChange={handleEditGallery}
                      value={selectGallert}
                    >
                      <FormControlLabel
                        value="Image"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14">
                            Additional images
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Video"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={<Typography className="fz-14">Video</Typography>}
                      />
                    </RadioGroup>
                  </Box>
                </Grid>
                {selectGallert === "Image" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box className=" ">
                        <Typography component="div" className="label">
                          Additional Images
                          <Typography component="span" className="star">
                            *
                          </Typography>
                        </Typography>

                        {state1.length < 1 && (
                          <Button
                            className="multiple_upload_btn"
                            variant="contained"
                            component="label"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                              Upload
                            </Box>
                            <input
                              accept={"image/png, image/jpg,image/jpeg"}
                              onChange={multipleImgChangeMultiple}
                              hidden
                              multiple
                              type="file"
                            />
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    {imageAddError && (
                      <p style={{ color: "red" }} className="fz-13 m-0 ml-20">
                        {imageAddError}
                      </p>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      {state1.length > 0 && (
                        <Grid
                          container
                          columnSpacing={1}
                          rowSpacing={1}
                          className="multiple_upload_container"
                        >
                          {state1.length > 0
                            ? state1.map((x, i) => (
                                <Grid item xs={6} sm={4} md={3} lg={3}>
                                  <Box className="uploaded_img">
                                    <Button
                                      className="close_icon"
                                      onClick={(e) => indexDelete(e, i)}
                                    >
                                      <Close />
                                    </Button>
                                    <img
                                      src={
                                        x.slice(0, 4) === "blob"
                                          ? x
                                          : process.env.REACT_APP_BASE_URL +
                                            `${x}`
                                      }
                                      alt=""
                                      height={155}
                                      width={195}
                                    />
                                  </Box>
                                </Grid>
                              ))
                            : ""}
                          <Grid item xs={6} sm={4} md={3} lg={3}>
                            <label
                              htmlFor="contained-button-file1"
                              className="w-full"
                            >
                              <Box className="multiple_upload_btn">
                                <Input
                                  inputProps={{
                                    accept: "image/png, image/jpg,image/jpeg",
                                  }}
                                  onChange={multipleImgChangeMultiple}
                                  style={{ display: "none" }}
                                  id="contained-button-file1"
                                  type="file"
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <FileUploadOutlinedIcon />
                                </Box>
                              </Box>
                            </label>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        className=" fz-12 mt-10 mb-10"
                        component="p"
                        variant="p"
                      >
                        <strong>Note:</strong> Image size should be more than
                        280px * 280px for better quality picture.
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <label className="form-labels" htmlFor="video-upload">
                        Video
                        <Typography component="span" className="star">
                          *
                        </Typography>
                      </label>
                      <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                        {selectedVideo && selectedVideo?.length === 0 && (
                          <Button
                            className="multiple_upload_btn"
                            variant="contained"
                            component="label"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                              Upload
                            </Box>
                            <input
                              id="video-upload"
                              accept="video/*"
                              onChange={handleVideoChange}
                              hidden
                              type="file"
                            />
                          </Button>
                        )}
                        {selectedVideo && selectedVideo?.length > 0 && (
                          <Grid
                            container
                            columnSpacing={1}
                            rowSpacing={1}
                            className="multiple_upload_container mb-10"
                          >
                            {selectedVideo.length > 0
                              ? selectedVideo.map((x, i) => (
                                  <Grid item xs={6} sm={4} md={4} lg={4}>
                                    <Box>
                                      <Button
                                        className="close_icon"
                                        onClick={(e) => clearVideo(e, i)}
                                      >
                                        <Close />
                                      </Button>
                                      <video className="uploaded_vdo" controls>
                                        <source
                                          src={
                                            x.slice(0, 4) === "blob"
                                              ? x
                                              : process.env.REACT_APP_BASE_URL +
                                                `${x}`
                                          }
                                          height={155}
                                          width={195}
                                        />
                                      </video>
                                    </Box>
                                  </Grid>
                                ))
                              : ""}
                          </Grid>
                        )}
                      </Box>
                      {imageAddError && (
                        <p style={{ color: "red" }} className="fz-13 m-0 ml-20">
                          {imageAddError}
                        </p>
                      )}
                    </Grid>
                  </>
                )}
                {activeStep === 1 && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      className="heading-btns mt-40"
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        onClick={handleBack}
                        variant="contained"
                        className="theme-btn  mr-10"
                      >
                        Back
                      </Button>

                      <Button
                        onClick={handleNext}
                        variant="contained"
                        className="theme-btn  "
                      >
                        Next
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {activeStep === 2 && (
            <Box className="steppy_content mb-10">
              <Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
                <EmailEditor
                  ref={emailEditorRef}
                  onLoad={onLoad}
                  projectId={144031}
                  tools={{
                    "custom#product_tool": {
                      // data: { products },
                      // properties: {
                      // 	productLibrary: {
                      // 		editor: {
                      // 			data: {
                      // 				products,
                      // 			},
                      // 		},
                      // 	},
                      // },
                    },
                  }}
                  options={{
                    designTags: {
                      business_name: "My business",
                      current_user_name: "Ron",
                    },
                    customCSS: [
                      "https://examples.unlayer.com/examples/product-library-tool/productTool.css",
                    ],
                    customJS: [
                      window.location.protocol +
                        "//" +
                        window.location.host +
                        "/custom.js",
                    ],
                  }}
                />
              </Grid>

              <Box className="heading-btns mt-20" style={{ textalign: "end" }}>
                <Button
                  onClick={handleEditor}
                  variant="contained"
                  className="theme-btn  mr-10"
                >
                  SaveDesign
                </Button>
              </Box>
            </Box>
          )}

          <>
            {activeStep === 2 && truebutton === false ? (
              <>
                <Box
                  className="heading-btns mt-20"
                  style={{ textAlign: "end" }}
                >
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    className="theme-btn  mr-10"
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleUpdateService}
                    variant="contained"
                    className="theme-btn"
                  >
                    Update
                  </Button>
                </Box>
              </>
            ) : (
              activeStep === 2 &&
              truebutton === true && (
                <>
                  <Box
                    className="heading-btns mt-20"
                    style={{ textAlign: "end" }}
                  >
                    <Button
                      onClick={handleBack}
                      variant="contained"
                      className="theme-btn  mr-10"
                    >
                      Back
                    </Button>
                    <Button variant="contained" className="theme-btn  mr-10">
                      Please wait...
                    </Button>
                  </Box>
                </>
              )
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
}
