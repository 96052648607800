import React, { useState } from "react";
import { Container, Typography, Checkbox, FormControlLabel, Button, Card, CardContent } from "@mui/material";

const MeetingConsent = () => {
const auth= JSON.parse(localStorage.getItem("userData"));
  const [agreed, setAgreed] = useState(false);

  const handleConsent = async () => {
    if (!auth?._id) {
        console.error("User ID is missing!");
        return;
    }

    try {
        if (agreed) {
            const authUrl = `${process.env.REACT_APP_BASE_URL}api/calendar/auth?userId=${auth._id}`;
            window.open(authUrl, "_blank"); // ? Opens in new tab
        }
    } catch (error) {
        console.error("Error initiating Google OAuth:", error);
    }
};

  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 4,mb:5, p: 3, boxShadow: 3 }}>
      <CardContent>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            Yoga Directory - Meeting Consent
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>
            🌿 <strong>Meeting Purpose:</strong>
          </Typography>
          <Typography variant="body2">
            - Directly connect with <strong>new yoga students</strong> interested in learning.
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>
            📅 <strong>About Google Meet:</strong>
          </Typography>
          <Typography variant="body2">
            - We use <strong>Google Meet</strong> for online sessions. <br />
            - You <strong>must have a Gmail account</strong> to access this service.
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>
            ✨ <strong>Maintain Decorum:</strong>
          </Typography>
          <Typography variant="body2">
            - Ensure a <strong>respectful and professional</strong> environment. <br />
            - Follow the <strong>ethics of yoga practice</strong> during sessions.
          </Typography>

          <FormControlLabel
            control={<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} />}
            label={<strong>I agree to the terms and conditions.</strong>}
            sx={{ mt: 2 }}
          />

          <Button
            variant="contained"
            className={agreed?"addbtn":"disabled"}
            fullWidth
            disabled={!agreed}
            onClick={handleConsent}
            sx={{ mt: 3 }}
          >
            Proceed to Google Consent
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MeetingConsent;

