import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import MiniDrawer from '../../../../../components/drawer';
import MeetingList from './MeetingList';
import MeetingConsent from './MeetingConcent';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const MeetingIndexPage = () => {
    const { userId } = useParams(); // Get userId from URL params
    const [auth, setAuth] = useState(null);

    useEffect(() => {
      if (userId) {
            // ✅ Fetch from API if no local storage auth is found
            const fetchUserDetails = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}Admin/individual-teachers/${userId}`);
                    console.log(response.data.teacher)
                    setAuth(response.data.teacher); 
                    // localStorage.setItem('auth', JSON.stringify(response.data.teacher)); // ✅ Store in localStorage
                } catch (error) {
                    console.error("Error fetching user details:", error);
                }
            };
            fetchUserDetails();
        }
    }, [userId]); // Run effect when userId changes

    // if (!auth) return <Typography>Loading...</Typography>;

  return (
    <>
    <Box className="companies dashboard">
				<MiniDrawer menuOrder={15} />
				<Box component="section" className="contentWraper">
					<Box className="headingBox">
						<Typography
							component="h4"
							variant="h4"
							className="Submenu-page-heading formTitle"
						>
							Meetings
						</Typography>
					</Box>
					<Box className="whiteContainer">
            {!auth ?  <Typography>Loading...</Typography> : auth?.AvailForMeeting ? <MeetingList/> : <MeetingConsent/> }
						{}
					</Box>
				</Box>
			</Box>
    </>
  )
}

export default MeetingIndexPage